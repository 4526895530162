// Rutas de negocios
import store from '@/store'
import {computed} from 'vue';

const negocio_id = computed(() => {
  let usuario = store.state.usuario.usuario;

  if(usuario.negocio && usuario.negocio.id){
    return usuario.negocio.id
  }
  return null
});
export default [

      {
            path:'/negocio/home',
            name: 'perfil.negocio.home',
            meta: {
                  resource:'home',
                  action:'read',
            },
            component: () => import('views/panels/negocio/home.vue'),
      },    


       /***************************************
       *    Tour Programados
       * *************************************/

       {
            path:'/tours/programados',
            component: () => import('views/perfiles/index.vue'),
            children:[
                  {
                        path:'',
                        name:'tour.programados',
                        component:() => import('views/perfiles/negocio/tourProgramados.vue'),
                        meta:{
                              resource:'Tours programados',
                              action:'read',
                              pageTitle:'Tour Programados'
                        }
                        
                  }
            ]
       },


      /***************************************
       *    Cargos Negocio
       * *************************************/
   
     
      /***************************************
       *    Información del Negocio
       * *************************************/

      /*****************************************/
      /* Convenio y terminos de negocio
      /*************************************** */


      {
      path:'/negocio/terminos',
      name:'perfil.negocio.terminos',
      beforeEnter: (to, from, next) => {
            store.dispatch('convenio/fetchPorPerfil','Negocio').then(({id}) => {
            if(id){
            next()
            }else{
            toast.error('Término no registrado')
            }
            })
      },
      component: () => import('views/perfiles/convenio.vue'),
      meta:{
            resource:'Perfil términos',
            action:'read',
      },
      },


      {
      path:'/negocio/convenios',
      name:'perfil.negocio.convenios',
      beforeEnter: (to, from, next) => {
            store.dispatch('termino/fetchTerminos',{model_id:negocio_id.value,model_type:'Negocio'}).then(({id}) => {
            if(id){
            next()
            }else{
            toast.error('Convenio no asociado')
            }
            })
      },
      component: () => import('views/perfiles/termino.vue'),
      meta:{
            resource:'Perfil convenios',
            action:'read',
      },
      },


      /***************************************
      *    Resultados 
      * *************************************/
      {
            path:'/resultados',
            component:() => import('components/Views.vue'),
            children:[
                  {
                        path:'',
                        component: () => import('views/perfiles/negocio/resultados/diarios.vue'),
                        name:'resultados.diarios',
                        meta:{
                              resource:'Resultados diarios',
                              action:'read',
                              pageTitle:'Resultados Diarios',
                              
                        }
                  },

                  {
                        path:'semanales',
                        component: () => import('views/perfiles/negocio/resultados/semanales.vue'),
                        name:'resultados.semanales',
                        meta:{
                              resource:'Resultados semanales',
                              action:'read',
                              pageTitle:'Resultados semanales',
                        }
                  }

            ]
      },


      /***************************************
      *    Pagos negocios 
      * *************************************/
      {
            path:'/negocios/pagos',
            component:() => import('components/Views.vue'),
            children:[
                  {
                        path:'',
                        name:'perfil.negocio.pagos',
                        component:() => import('views/perfiles/negocio/pagos/list.vue'),
                        meta:{
                              pageTitle:'Pagos realizados',
                              resource:'Pagos negocio',
                              action:'read',
                        }
                  }
            ]
      },

      /***************************************
      *    Reportes 
      * *************************************/
      
      {
            path:'/negocio/reportes',
            component:() => import('components/Views.vue'),
            children:[
                  {
                        path:'tours',
                        name:'reporte.negocio.tours',
                        props:() => ({
                              negocioId:negocio_id.value
                        }),
                        component:() => import('views/reportes/negocio/tours.vue'),
                        meta:{
                              resource:'Reporte negocios tours',
                              action:'read',
                              pageTitle:'Reporte de Tours Recibidos'
                        }

                  }
            ]
      }

]